import { Box, Typography } from "@material-ui/core"
import React from "react"
import { format } from "date-fns"
import { fr } from "date-fns/locale"

export default function UserBookingHeader({
  classes,
  isMobile,
  startDate,
  description,
  booking,
  headerBookingPro = false,
}) {
  const headerText = description
    ? headerBookingPro
      ? `Rendez-vous du ${format(startDate, "P", {
          locale: fr,
        }).replace(/\//g, ".")} avec ${booking.customerGivenName} ${
          booking.customerName
        }`
      : description.replace(
          "Vous avez rendez-vous",
          `Rendez-vous du ${format(startDate, "P", {
            locale: fr,
          }).replace(/\//g, ".")}`
        )
    : ""
  return (
    <Box marginBottom={2} width="100%">
      <Typography
        variant="h4"
        className={classes.title}
        style={{
          margin: 16,
          flex: 1,
          fontSize: isMobile && 18,
          // marginLeft:  32
        }}
      >
        {headerText}
      </Typography>
      {/* <Button variant="contained" className={classes.spaced}>
      Modifier le rendez-vous
    </Button>
      <Button variant="contained" className={classes.spaced}>
        Annuler le rendez-vous
      </Button> */}
      {/* 
        <IconButton
          className={classes.spaced}
          onClick={fetchData}
          style={{ background: "white" }}
        >
          <RefreshIcon />
        </IconButton> */}
    </Box>
  )
}
