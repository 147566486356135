import React from "react"
import { API } from "aws-amplify"
// import { getBooking } from "../graphql/queries"
import ErrorComponent from "./ErrorComponent"
import { makeStyles, Typography } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import LoadingBackdrop from "./LoadingBackDrop"
import useIsMobile from "../utils/useIsMobile"
import { useTheme } from "@material-ui/core/styles"
import "./BookingWrapper.css"
import VisioComponent from "./VisioComponent"
import UserBookingHeader from "./UserBookingHeader"
import UserBookingTabs from "./UserBookingTabs"
import UserBookingTabPanels from "./UserBookingTabPanels"

const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      status
      start
      end
      bookReason
      bookType
      delivery
      location
      deliveryPrice
      totalAmount
      canShip
      canSell
      recipe
      description
      meetUrl
      project {
        meta
      }
      shipping {
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        name
      }
      payment_status
    }
  }
`

const useStyles = isMobile =>
  makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    root: {
      display: "flex",
      flex: 1,
      overflow: "hidden",
      flexDirection: "column",
      width: "100%",
      // paddingRight: 0,
      background: theme.palette.background.main,
      // color: theme.palette.primary.main,
    },
    videoRoot: {
      display: "flex",
      position: "relative",
      overflow: "hidden",
      // flexDirection: "column",
      width: "100%",
      height: "100%",
      background: theme.palette.background.main,
      // color: theme.palette.primary.main,
      // marginTop: 64,
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      margin: isMobile && theme.spacing(2),
      //textAlign: "center",
      fontSize: isMobile ? 18 : 30,
      marginLeft: isMobile && "26px",
      fontWeight: isMobile && "bold",
    },
    subtitle: {
      margin: theme.spacing(2),
      marginLeft: 0,
    },
    paper: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      // color: theme.palette.primary.main,
      display: "flex",
      flexDirection: "column",
    },
    spaced: { margin: theme.spacing(2) },
  }))

export default function BookingWrapper({ id, location }) {
  const isMobile = useIsMobile()
  const classes = useStyles(isMobile)()

  const [data, setData] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const {
    status,
    start,
    description,
    end,
    bookReason,
    bookType,
    payment_status,
    shipping,
    deliveryPrice,
    totalAmount,
    canShip,
    recipe,
  } = data || {}

  const startDate = new Date(start)
  const endDate = new Date(end)

  const [value, setValue] = React.useState(0)

  React.useEffect(() => {
    setValue(
      Date.now() > startDate &&
        ["paid", "sent", "delivered", "waiting payment"].includes(status)
        ? 1
        : 0
    )
  }, [data])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  //responsive design
  console.log("BookingWrapper", location)
  const [showVisio, setShowVisio] = React.useState(false)
  const [bookingStatus, setBookingStatus] = React.useState({
    started: false,
    ended: false,
  })

  const hideVisioComponent = () => setShowVisio(false)
  const showCallVideo = () => setShowVisio(true)

  const { started, ended } = bookingStatus
  const fetchData = async () => {
    setLoading(true)
    API.graphql({
      query: getBooking,
      variables: { id },
      authMode: "AWS_IAM",
    })
      .then(data => {
        console.log({ booking: data })
        setData(data.data.getBooking)
      })
      .catch(err => {
        console.warn(err)
        setError(err)
      })
      .finally(() => setLoading(false))
  }
  React.useEffect(() => {
    fetchData()
  }, [id])
  if (error) return <ErrorComponent error={error} />
  // if (loading) return <LoadingComponent />

  const slot =
    start && end
      ? `${format(startDate, "p", {
          locale: fr,
        })} - ${format(endDate, "p", { locale: fr })} , le ${format(
          startDate,
          "P",
          {
            locale: fr,
          }
        ).replace(/\//g, ".")} `
      : ""
  if (started && !ended && bookType === "Visio" && showVisio) {
    return (
      <VisioComponent
        classes={classes}
        hideVisioComponent={hideVisioComponent}
        id={id}
      />
    )
  }
  const meta = JSON.parse(data?.project?.meta || "{}")
  const address = `${meta.address} ${meta.zip_code} ${meta.city}`

  console.log({ start, end, started, ended, id, description })
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "100%",
          overflowY: "auto",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            overflowX: "hidden",
            width: "100%",
            flexDirection: "column",
            margin: 0,
            padding: 0,
            maxWidth: 720,
          }}
        >
          <UserBookingHeader
            classes={classes}
            isMobile={isMobile}
            startDate={startDate}
            description={description}
          />
          <UserBookingTabs
            isMobile={isMobile}
            value={value}
            handleChange={handleChange}
            startDate={startDate}
            status={status}
          />
          <UserBookingTabPanels
            value={value}
            isMobile={isMobile}
            ended={ended}
            bookType={bookType}
            status={status}
            slot={slot}
            bookReason={bookReason}
            bookingStatus={bookingStatus}
            setBookingStatus={setBookingStatus}
            start={start}
            end={end}
            startDate={startDate}
            recipe={recipe}
            totalAmount={totalAmount}
            deliveryPrice={deliveryPrice}
            setLoading={setLoading}
            payment_status={payment_status}
            id={id}
            loading={loading}
            canShip={canShip}
            shipping={shipping}
            address={address}
            classes={classes}
            started={started}
            showCallVideo={showCallVideo}
            booking={data || {}}
          />
          <LoadingBackdrop open={!!loading} />
        </div>
      </div>
    </div>
  )
}
