import { IconButton, makeStyles } from "@material-ui/core"
import React from "react"
import CancelIcon from "@material-ui/icons/Cancel"

const useStyles = makeStyles(theme => ({
  videoRoot: {
    display: "flex",
    position: "relative",
    overflow: "hidden",
    // flexDirection: "column",
    width: "100%",
    height: "100%",
    background: theme.palette.background.main,
    // color: theme.palette.primary.main,
    // marginTop: 64,
  },
}))

export default function VisioComponent({ hideVisioComponent, id }) {
  const classes = useStyles()
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        height: 100,
      }}
    >
      <div className={classes.videoRoot}>
        <iframe
          allow="camera;microphone"
          src={`https://main.dsc3qyvy1mibr.amplifyapp.com/ng/${id}`}
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: 0,
          }}
        />
        <IconButton onClick={hideVisioComponent}>
          <div
            style={{
              position: "absolute",
              left: 20,
              top: 20,
              width: 60,
              height: 60,
              background: "white",
              borderRadius: 30,
            }}
          >
            <CancelIcon style={{ width: 60, height: 60 }} />
          </div>
        </IconButton>
      </div>
    </div>
  )
}
