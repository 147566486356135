import React, { Fragment } from "react"
import UserBooking from "../components/UserBooking"
import { Router } from "@reach/router"
import LoadingBackdrop from "../components/LoadingBackDrop"
import VideoBooking from "../components/VideoBooking"

const browser = typeof window !== "undefined" && window

function Booking({ location }) {
  return browser ? (
    <Router primary={false} component={Fragment}>
      <UserBooking path={"/booking/:id"} location={location}></UserBooking>
      <VideoBooking path={"/booking/:id/video"} location={location} />
    </Router>
  ) : (
    <LoadingBackdrop open />
  )
}

export default Booking
