import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Footer from "./Footer"
import AppBar from "./AppBar"
import BookingWrapper from "./BookingWrapper"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "absolute",
    height: "100vh",
    width: "100%",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  root: {
    position: "relative",
    display: "flex",
    flex: 1,
    overflow: "hidden",
    flexDirection: "column",
    // height: "100%",
    margin: 0,
    padding: 0,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    display: "flex",
    // flex: 1,
    // flexDirection: 'column',
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    marginTop: theme.spacing(2),
  },
  dashboardContent: {
    display: "flex",
    flex: 1,
    width: "100%",
    overflow: "hidden",
    flexDirection: "column",
  },
  toolbar: {
    display: "flex",
  },
}))
export default function DrawerWrapper({ location, id }) {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <AppBar location={location} />
        <main className={classes.dashboardContent}>
          <Toolbar className={classes.toolbar} />
          <BookingWrapper id={id} location={location} />
        </main>
      </div>
      <Footer />
    </div>
  )
}
