import { Typography } from "@material-ui/core"
import React from "react"

export default function ErrorComponent() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h1" style={{ textAlign: "center" }}>
        😞
      </Typography>
      <Typography variant="h6">
        Désolés, nous n'avons pas pu afficher les données. Nous faisons le
        nécessaire pour corriger le problème
      </Typography>
    </div>
  )
}
